import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../../../components/container'
import Layout from '../../../components/layout'
import banner from '../../../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
  min-height: 750px;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Text = styled.p``

const CursoMecanicaAviao = () => (
  <Layout title='Curso de Comissário de Voo' page='educacao/cursos/comissario-de-voo'>
    <Banner />
    <Content>
      <Title>Curso de Comissário de Voo</Title>

      <Text>
        O Aeroclube do Rio Grande do Norte, está recebendo as inscrições para nova turna
        de comissário de voo.
        <br />
        <br />
        O curso poderá ser realizado já em agosto, a depender do númro mínimo de
        inscritos.
        <br />
        <br />
        Os interessados deverão procurar a Secretaria do Aeroclube, situado na Av. Hermes
        da Fonseca ou através do Telefone 84-3211-1840.
        <br />
        <br />
        Caso queiram acessar por e-mail favor contactar contato@aeroclubern.com.br
        <br />
        <br />
        Procedimentos antes de iniciar o Curso:
        <br />
        <br />
        INSCRIÇÃO
        <br />
        <br />
        Idade 18 anos Grau de Instrução CMS (ensino médio). DOCUMENTOS (CÓPIAS E
        ORIGINAIS)
        <br />
        <br />
        RG / CPF/ TITULO DE ELEITOR RESERVISTA (homens) Certificado de 2º grau Comprovante
        de Residência 02 fotos 3×4
        <br />
        <br />
        INVESTIMENTOS
        <br />
        <br />
        (Curso) R$ 2.200,00*
        <br />
        <br />
        No cartão: Em até 5x no cartão.
        <br />
        <br />
        **No ato da matricula, taxa: R$ 200,00
        <br />
        <br />
        Inspeção de saúde na BASE AÉREA
        <br />
        <br />
        Exame saúde: FONE: 3644-7666/8026. Ligar no dia marcado para a inspeção, levar
        Comprovante GRU do Pagamento de R$ 451,40 o resultado dos exames extras. GERAR GRU
        DE EXAME DE SAÚDE
        <br />
        <br />
        Preencha os dados abaixo nos campos indicados:
        <br />
        <br />
        Unidade Gestora (UG): 120101
        <br />
        <br />
        Gestão: 00001 – Tesouro Nacional
        <br />
        <br />
        Código de Recolhimento: selecione 22041-8 – Fdo Aeron Serv Hospitalares
        <br />
        <br />
        Clicar em avançar
        <br />
        <br />
        Conferir o cabeçalho e depois preencher os campos:
        <br />
        <br />
        Nº de referência: 7670
        <br />
        <br />
        Competência (mm/aaaa): mês e ano do pagamento
        <br />
        <br />
        Vencimento (dd/mm/aaaa): dia, mês e ano do pagamento.
        <br />
        <br />
        CPF do contribuinte: nº do seu CPF
        <br />
        <br />
        Nome do contribuinte: nome completo
        <br />
        <br />
        Valor principal: R$ 590,00 (CMA Inicial)
        <br />
        <br />
        DIAS E HORÁRIOS DE AULA
        <br />
        <br />
        De 2ª a 6ª feira das 19:00 às 22:00hs HOMEM (fardamento)
        <br />
        <br />
        Terno, Meia e sapatos pretos Camisa social Azul-bebê + gravata MULHER (fardamento)
        Conjunto preto (saia abaixo joelho + blazer + Camisa social Branca + lenço )
        Meia-calça e sapatos de salto alto PRETOS Cabelos presos + maquiagem
        <br />
        <br />
        Para esclarecimentos
        <br />
        <br />
        Os interessados deverão procurar a Secretaria do Aeroclube, situado na Av. Hermes
        da Fonseca ou através do Telefone 84-3211-1840.
      </Text>
    </Content>
  </Layout>
)

export default CursoMecanicaAviao
